.header {
    &-top {
        padding: 15px 0;
        border-bottom: 1px $light-gray-color solid;
    }
    &-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include media-breakpoint-down(sm) {
            width: 100%;
            flex-direction: row;
        }
        img {
            width: 200px;
            margin-right: 110px;
        }
    }
    .navbar {
        padding: 17px 1rem;
    }
    .select-wrapper {
        position: relative;
        &__item {
            span {
                padding-right: 15px;
            }
        }
        svg {
            position: absolute;
            right: 25px;
            top: 12px;
        }
    }
    .custom-select {
        font-family: Montserrat;
        font-weight: bold;
        font-size: 14px;
        border: none;
        background-image: url("data:image/svg+xml,%3Csvg className='bi bi-chevron-down' width='1.5em' height='1.5em' viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z' clipRule='evenodd'/%3E%3C/svg%3E");
        background-position: 85%, 50%;
        background-size: 12px 15px;
        &:focus {
            box-shadow: none;
        }
    }
    .navbar-link {
        font-family: Montserrat;
        font-weight: bold;
        font-size: 14px;
        margin: 0 1.2rem;
        transition: text-shadow 0.2s ease-in-out;
        &:hover {
            color: #000;
            text-decoration: none;
            text-shadow: 3px 2px 0 rgba(77, 233, 255, 0.49);
        }
        &:first-child {
            margin-left: 0;
            @include media-breakpoint-down(xl) {
                padding-left: 1.2rem;
            }
        }
        &:hover {
            color: #000;
            text-decoration: none;
        }
        &.active {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -32px;
                width: 100%;
                height: 4px;
                background-color: $brand-color;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
            }
        }
    }
    .phone {
        font-size: 25px;
        font-family: GilroyBold;
    }
    .fa-phone {
        font-size: 27px;
        padding-top: 10px;
    }
    .client-location {
        .custom-select {
            font-size: 12px;
            font-weight: 500;
            border: none;
            border-bottom: 1px dotted #000;
            border-radius: 0;
            background-image: none;
            padding: 0;
            margin: 0 10px;
            height: 25px;
        }
    }
    .location-street {
        font-size: 12px;
        font-weight: 500;
        font-family: Montserrat;
        width: 140px;
        margin-bottom: 2px;
    }
    .desktop-navigation {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .mobile-menu-btn {
        display: none;
        @include media-breakpoint-down(sm) {
            display: flex;
            align-items: center;
        }
    }
    .mobile-menu {
        display: none;
        padding: 0 15px;
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #fff;
            z-index: 6;
        }
        a {
            font-family: Montserrat;
            font-size: 28px;
            font-weight: bold;
            display: block;
            margin-top: 15px;
        }
        a:first-of-type {
            margin-top: 0;
        }
        .mobile-close {
            height: 35px;
            width: 50px;
            position: absolute;
            right: 10px;
            top: 25px;
        }
    }
}
