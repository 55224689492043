.footer {
    border-top: 1px #f0f2f9 solid;
    font-size: 0.8rem;
    &-wrapper {
        padding: 2rem 0;
    }
    &-logo {
        display: flex;
        align-items: center;
        @include media-breakpoint-down(sm) {
            display: none;
        }
        img {
            width: 150px;
        }
        p {
            font-family: Montserrat;
            font-size: 28px;
            font-weight: bold;
            margin: 0 0 0 15px;
        }
    }
    a {
        font-size: 0.8rem;
    }
    ul {
        padding-left: 0;
        list-style: none;
    }
    i {
        padding-right: 1rem;
    }
    .copyright {
        font-size: 12px;
        margin-bottom: 0.5rem;
    }
}
