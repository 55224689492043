.contacts {
    &-wrapper {
        padding: 4rem 0;
    }
    .title {
        margin-bottom: 2rem;
    }
    .sub-title {
        margin: 0;
    }
    .contacts-details {
        margin: 1.5rem 0;
        @include media-breakpoint-down(sm) {
            padding: 0;
            margin-top: 0;
        }
        p {
            span {
                color: #8189a9;
            }
        }
    }
    &-item {
        padding: 2rem 0;
        &:first-child {
            border-bottom: 2px solid #ccc;
        }
    }
    button {
        background: none;
        border: none;
    }
    .card-header {
        cursor: pointer;
        background: transparent;
        border-bottom: none;
        @include media-breakpoint-down(sm) {
            padding: 30px 0 30px;
        }
        p {
            margin-bottom: 5px;
        }
    }
    .city {
        font-family: Montserrat, serif;
        font-size: 14px;
        font-weight: bold;
        color: #8189a9;
    }
    .street {
        font-family: Montserrat, serif;
        font-size: 25px;
        font-weight: bold;
        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }
    }
    .accordion {
        img {
            transform: translateY(10px) rotateZ(180deg);
        }
        .active {
            img {
                transform: translateY(10px);
            }
        }
        .card {
            border: none;
            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
            &-body {
                @include media-breakpoint-down(sm) {
                    padding-top: 0;
                }
            }
        }
    }
}

.info-window {
    .title {
        font-size: 14px;
        margin-bottom: 2rem;
    }
    p {
        margin-bottom: 3px;
    }
    a {
        font-size: 12px;
    }
}
