.prices {
    &-wrapper {
        padding: 3rem 0;
    }
    &__problem {
        font-family: Montserrat;
        font-weight: 700;
    }
    &-table {
        padding: 3rem 0;
        border-top: 1px solid #f0f2f9;
        li {
            padding: 10px;
            cursor: pointer;
        }
        .active {
            background: $brand-color;
            color: #fff;
            margin-right: 15px;
            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }
        .titles {
            border-right: 1px solid #f0f2f9;
            @include media-breakpoint-down(sm) {
                border-right: none;
            }
        }
        .subtitles {
            .row:nth-child(odd) {
                background: #f0f2f9;
            }
        }
    }
}
