.examples {
    padding: 50px 0;
    background-color: #e6fbfb;
    .title {
        color: #2b2d38;
        .before:before {
            background-color: #8de6d1;
        }
    }
    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 100px auto 0;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
    &-item {
        width: 280px;
        margin-bottom: 100px;
        margin-right: 30px;
        font-family: Montserrat, sans-serif;
        box-shadow: 0 0 29px 0 rgba(0, 110, 139, 0.17);
        @include media-breakpoint-down(sm) {
            margin: 20px auto;
        }
        &-title {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 1.2rem;
            text-transform: uppercase;
        }
        &-text-block {
            padding: 15px;
            background-color: #acf1ea;
            width: 280px;
            min-height: 110px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }
        img {
            width: 280px;
            height: 400px;
        }
    }
}
