.services {
    margin-top: 100px;
    &-wrapper {
        padding: 0 0 80px;
        @include media-breakpoint-down(sm) {
            padding: 0 0 40px;
        }
    }
    &-descr {
        width: 60%;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    &-item {
        padding: 15px;
        margin: 15px 0;
        border-radius: 5px;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
        text-align: center;
        .sub-title {
            font-size: 14px;
            width: 70%;
            min-height: 50px;
            margin: 15px auto 0;
            @include media-breakpoint-down(sm) {
                font-size: 12px;
                width: 100%;
            }
        }
        .img-box {
            width: 100px;
            height: 80px;
            margin: auto;
            img {
                margin-top: 15px;
            }
        }
    }
}
