.our-team {
    width: 95%;
    margin: 0 auto;
    &-wrapper {
        position: relative;
        padding: 50px 0 100px;
        @include media-breakpoint-down(sm) {
            padding: 50px 0;
            select[name='city'] {
                width: 90%;
                margin: 0 auto 30px;
            }
        }
    }
    &__all-doctors {
        position: absolute;
        right: 50px;
        bottom: 50px;
        font-size: 12px;
        color: $brand-color;
        &:hover {
            color: $brand-color;
        }
        img {
            padding-left: 10px;
        }
    }
    .our-team {
        max-width: 1400px;
        margin: 0 auto;
        select {
            width: 300px;
            margin: 0 auto 25px;
        }
    }
    .our-team__list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 100px;
    }
    .our-team__item {
        width: 350px;
        margin: 15px 0;
        .btn {
            padding: 5px 10px;
        }
    }
    &-item {
        margin: 0 15px;
        &-info {
            padding: 20px 15px;
            border: 1px solid #f0f2f9;
            .qualification {
                font-size: 13px;
                border: 1px solid #ccc;
                padding: 5px 10px;
                border-radius: 20px;
                margin-right: 7px;
                margin-bottom: 10px;
                &-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            .location {
                margin-top: 20px;
            }
        }
        img {
            width: 100%;
            height: auto;
        }
        .doctor-img {
            height: 420px;
            object-fit: cover;
            &.position-top {
                object-position: top;
            }
        }
    }
    .title {
        padding: 2rem 0;
    }
    button {
        width: 100%;
    }
    .counter {
        display: none;
        @include media-breakpoint-down(sm) {
            display: block;
            margin: 35px 0 0 20px;
            color: #a5acc8;
            font-weight: bold;
            span {
                color: #000;
            }
        }
    }
}

.doctor-info {
    padding: 30px;
    width: 650px;
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
    .modal-content {
        top: 0;
        transform: none;
    }
    &__locations {
        display: flex;
        justify-content: space-around;
    }
    .title {
        font-size: 25px;
        margin-top: 15px;
    }
    .qualification {
        width: 200px;
        font-size: 13px;
        border: 1px solid #ccc;
        padding: 5px 10px;
        border-radius: 20px;
        margin-right: 7px;
    }
    .accordion__list {
        text-align: left;
        list-style: disc;
        padding-left: 30px;
        &-item {
            font-size: 12px;
        }
    }
    .doctor-img {
        width: 210px;
        margin: 0 auto;
    }
    .accordion__text {
        margin: 5px 0;
    }
}

.slick-dots {
    bottom: -50px;
    li {
        button {
            &:before {
                font-size: 12px;
                color: #ccc;
                border-radius: 50%;
                width: 12px;
                height: 12px;
                line-height: 12px;
            }
            &:hover:before,
            &:hover:before {
                opacity: 0.7;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        &.slick-active button:before {
            color: $brand-color;
            opacity: 1;
            border: none;
        }
    }
}
