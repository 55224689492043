.benefits {
    &-wrapper {
        padding: 80px 0;
        @include media-breakpoint-down(sm) {
            padding: 40px 0;
            overflow: hidden;
        }
    }
    &-descr {
        padding: 1rem 0;
    }
    &-img {
        width: 70px;
        height: 60px;
    }
    &-item {
        padding: 15px;
        @include media-breakpoint-down(sm) {
            padding: 15px 0 15px;
        }
    }
    .col-lg-4:nth-child(-n + 3) {
        border-bottom: 1px solid #f0f2f9;
        @include media-breakpoint-down(sm) {
            border: none;
        }
    }
    .col-lg-4:nth-child(-3n + 5) {
        border-right: 1px solid #f0f2f9;
        border-left: 1px solid #f0f2f9;
        @include media-breakpoint-down(sm) {
            border: none;
        }
    }
    .col-6:nth-child(odd) {
        @include media-breakpoint-down(sm) {
            border-right: 1px solid #f0f2f9;
        }
    }
    .col-6:not(:nth-child(n + 5)) {
        @include media-breakpoint-down(sm) {
            border-bottom: 1px solid #f0f2f9 !important;
        }
    }
    .sub-title {
        padding: 15px 0 0;
        margin-bottom: 10px;
    }
}
