.one-step {
    background-image: url('../../images/doctors/one-step.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100% 0;
    @include media-breakpoint-down(sm) {
        background-image: none;
    }
    &-wrapper {
        background: #f0f2f9;
        @include media-breakpoint-down(sm) {
            padding: 40px 0;
        }
        .container {
            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
        }
    }
    &__form {
        padding: 60px 0;
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }
    .form-wrapper {
        width: 80%;
        .title {
            margin-bottom: 0;
        }
        p {
            font-size: 18px;
            color: #15c39a;
        }
        select {
            margin-bottom: 1rem;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    .container {
        background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgba(255, 255, 255, 0)),
            color-stop(45%, #f0f2f9)
        );
        background-image: linear-gradient(
            to left,
            rgba(255, 255, 255, 0) 0,
            #f0f2f9 45%
        );
    }
    &-benefits {
        margin: 0 0 2rem 2.5rem;
        list-style: url("data:image/svg+xml,%3Csvg class='bi bi-check' width='1.5em' height='1.5em' viewBox='0 -4 16 16' fill='%2315c39a' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z' clip-rule='evenodd'/%3E%3C/svg%3E");
        li {
            padding-left: 0;
        }
    }
    &-img {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .title {
        font-size: 43px;
        @include media-breakpoint-down(sm) {
            font-size: 28px;
        }
    }
    .sub-title {
        padding: 1rem 0;
    }
    img {
        width: 100%;
    }
    input {
        margin-bottom: 1rem;
    }
    button {
        width: 100%;
    }
}
