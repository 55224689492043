$text-color: #2b2d38;
$bg-color: #fbfbfb;
$brand-color: #32e2e2;
$light-gray-color: #f0f2f9;

#root {
    overflow-x: hidden;
}

body,
a {
    color: $text-color;
    font-family: GilroyLight;
    font-size: 14px;
    &:hover {
        text-decoration: none;
        color: $text-color;
    }
}
p {
    font-family: Montserrat;
}
.strong {
    font-family: Montserrat;
    font-weight: bold;
}
.title {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 45px;
    color: #04fefd;
    @include media-breakpoint-down(sm) {
        font-size: 28px;
    }
    span {
        position: relative;
        z-index: 0;
        &.before:before {
            content: '';
            position: absolute;
            width: 300%;
            height: 12px;
            background-color: #f0f2f9;
            bottom: 10px;
            left: -201%;
            z-index: -1;
        }
        &.after:after {
            content: '';
            position: absolute;
            width: 600%;
            height: 12px;
            background-color: #8de6d1;
            bottom: 5px;
            left: 0;
            z-index: -1;
        }
    }
}
.sub-title {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 18px;
    &--sm {
        font-size: 18px;
    }
}
.contacts-details {
    font-size: 12px;
    p {
        margin-bottom: 5px;
    }
    a {
        font-size: 12px;
    }
}
ul {
    list-style: none;
    padding-left: 0;
}
.btn {
    background-color: $brand-color;
    color: #fff;
    margin: 5px 0;
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
        color: #fff;
        box-shadow: 0 10px 10px rgba(77, 233, 255, 0.35);
    }
}
.pointer {
    cursor: pointer;
}
.text-sm {
    font-size: 12px;
    color: #969595;
}

.modal-custom {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: none;
    background: white;
    overflow: auto;
    outline: none;
    padding: 100px;
    width: 650px;
    text-align: center;
    z-index: 50;
    @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 15px;
    }
    .close-modal {
        position: absolute;
        z-index: 5;
        right: 15px;
        top: 15px;
        outline: none;
        border: none;
        background: #fff;
    }
    .btn {
        width: 100%;
    }
    h3 {
        font-family: Montserrat;
        font-weight: bold;
        font-size: 45px;
        text-align: left;
    }
    p {
        color: #8189a9;
        font-weight: bold;
        text-align: left;
        margin: 20px 0;
        font-size: 12px;
        &.warning {
            color: #ef2323;
            margin-bottom: 5px;
        }
    }
    .form-control {
        background-color: #f0f2f9;
        margin-bottom: 20px;
    }
    .modal-content {
        top: 50%;
        transform: translateY(-50%);
        border: none;
    }
}

.form-field {
    margin-bottom: 20px;
    p.warning {
        color: #ef2323;
        margin-bottom: 5px;
    }
}

.success-notification {
    position: fixed;
    width: 50%;
    height: 130px;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    background: #f4f8ff;
    z-index: 5;
    text-align: center;
    padding: 10px;
    border: 1px solid #f0f2f9;
    border-radius: 5px;
    animation-name: example;
    animation-duration: 1s;
    box-shadow: 0 2px 6px rgba(196, 196, 217, 0.22);
    @include media-breakpoint-down(sm) {
        width: 90%;
        text-align: left;
        height: 150px;
    }
    .title {
        font-size: 25px;
        margin-top: 10px;
        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }
    }
}
@keyframes example {
    from {
        transform: translateX(-50%) scale(0.3);
    }
    to {
        transform: translateX(-50%) scale(1);
    }
}

.mobile-only {
    display: none;
    @include media-breakpoint-down(sm) {
        display: block;
    }
}
